// extracted by mini-css-extract-plugin
export var schedule = "timetable-events-module--schedule--A4lCQ";
export var timeSlot = "timetable-events-module--timeSlot--unzrv";
export var weekDay = "timetable-events-module--weekDay--Vsmqk";
export var session = "timetable-events-module--session--nUmnB";
export var sessionTitle = "timetable-events-module--sessionTitle--z5BD9";
export var sessionTime = "timetable-events-module--sessionTime--xzBDj";
export var sessionPresenter = "timetable-events-module--sessionPresenter--RnXPn";
export var gridHeader = "timetable-events-module--gridHeader--OifB3";
export var yoga_flow = "timetable-events-module--yoga_flow--QZ0o7";
export var prep_dance = "timetable-events-module--prep_dance--t8g-v";
export var pilates = "timetable-events-module--pilates--S5oXq";
export var hip_hop = "timetable-events-module--hip_hop--K+s8z";
export var cardio_pilates = "timetable-events-module--cardio_pilates--tJ2vw";
export var modern_dance = "timetable-events-module--modern_dance--4E5IU";
export var allenamento_funzionale = "timetable-events-module--allenamento_funzionale--avieM";
export var giocodanza = "timetable-events-module--giocodanza--PWlOz";
export var power_pilates = "timetable-events-module--power_pilates--bXhQP";
export var ballet = "timetable-events-module--ballet--mfs1p";
export var tonificazione = "timetable-events-module--tonificazione--h3wob";
export var pilates_gag = "timetable-events-module--pilates_gag--OnkU4";
export var heels = "timetable-events-module--heels--r40eN";
export var dynamic_pilates = "timetable-events-module--dynamic_pilates--k-d18";
export var pilates_base_stretching = "timetable-events-module--pilates_base_stretching--Z-WB8";
export var workshops = "timetable-events-module--workshops--wmpk9";
export var postural_pilates = "timetable-events-module--postural_pilates---eoGI";
export var videodance = "timetable-events-module--videodance--HcWRk";
export var walking_pilates = "timetable-events-module--walking_pilates--Vi3-9";
export var individual_lesson = "timetable-events-module--individual_lesson--fUO1c";
export var aerial_yoga = "timetable-events-module--aerial_yoga--0oWU9";
export var preparatory_technique = "timetable-events-module--preparatory_technique--Lunaz";
export var ginnastica_maschile = "timetable-events-module--ginnastica_maschile--BEGyh";
export var trx = "timetable-events-module--trx--F3n0Q";
export var trackAll = "timetable-events-module--trackAll--4iOh0";
export var dayTitle = "timetable-events-module--dayTitle--ZImdK";
export var modalContent = "timetable-events-module--modalContent--1S4Kj";